import './index.scss';
import Router from './router';
import React, {useState, useCallback, useEffect} from 'react';
// import GlobalStateProvider './service/global-state';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { request, methods } from "./service/http-common";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CONFIG } from './service/config.js'; 
// import { useNavigate } from "react-router-dom";

const GlobalStateProvider = React.lazy(() => import('./service/global-state'))

function App() {

  useEffect(() => {
    const interval = setInterval(() => {
      const sessionExpAtMsn = window.sessionExpDate
      const isLoggedIn = localStorage.getItem('loggedInUser')

      if (sessionExpAtMsn !== undefined && isLoggedIn) {
        let currentDate = new Date();
        if (CONFIG.timezone_exp_time === "UTC") {
          currentDate = moment(new Date().toISOString(), 'YYYY-MM-DD, h:mm:ss').toDate() 
        }
        const expTime = moment(sessionExpAtMsn, 'DD MMM YYYY h:mm:ss').toDate()//"15 Jan 2025 11:39:46"
        // console.log(currentDate, " > ",expTime, " = ",currentDate > expTime)
        if (currentDate > expTime) {
          toast.dismiss()
          toast.error('Session expired. Please login again.', {
            autoClose: 1500,
            onClose: ()=>{
              request(`user-mgmt/user/logout`, methods.DELETE)
              localStorage.clear()
              window.sessionExpDate = undefined
              window.location.href = `/login?redirectUri=${window.location.href}`;
            }
          });
        }
      }
    }, 3000);
  
    return () => clearInterval(interval);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
    <GlobalStateProvider>
      <div className="App">
        <Router />
        <ToastContainer autoClose={3000}/>
      </div>
    </GlobalStateProvider>
    </I18nextProvider>
  );
}

export default App;
