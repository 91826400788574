const {ws_base_url,chat_secret_key, api_base_url, chat_base_url, chat_live, chat_api, user_mgmt_url, analytic_studio, timezone_exp_time } = window['runConfig'];

export const CONFIG = {
    ws_base_url,
    api_base_url,
    chat_live,
    chat_api,
    chat_secret_key,
    user_mgmt_url,
    analytic_studio,
    timezone_exp_time
}

